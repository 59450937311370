import _defineProperty from "/builds/u5yGSXwH/1/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

export var useGetMaterials = function useGetMaterials() {
  var getMaterialsFromPricing = function getMaterialsFromPricing(validationMaterials) {
    var _validationMaterials$;

    return (validationMaterials === null || validationMaterials === void 0 ? void 0 : (_validationMaterials$ = validationMaterials.getPricingForMultipleProducts) === null || _validationMaterials$ === void 0 ? void 0 : _validationMaterials$.reduce(function (keyVal, next) {
      if (!!next) {
        var _next$materialPricing;

        (_next$materialPricing = next.materialPricing) === null || _next$materialPricing === void 0 ? void 0 : _next$materialPricing.forEach(function (material) {
          if (material) {
            keyVal[material.materialNumber] = {
              marketplaceOfferId: material.marketplaceOfferId,
              catalogType: material.catalogType
            };
          }
        });
      }

      return keyVal;
    }, {})) || {};
  };

  var getMaterialsWithMarketPlaceData = function getMaterialsWithMarketPlaceData(materials, materialsFromPricing) {
    return materials.map(function (material) {
      var _materialsFromPricing, _materialsFromPricing2;

      return _objectSpread(_objectSpread({}, material), {}, {
        catalogType: (_materialsFromPricing = materialsFromPricing[material.materialNumber]) === null || _materialsFromPricing === void 0 ? void 0 : _materialsFromPricing.catalogType,
        marketplaceOfferId: (_materialsFromPricing2 = materialsFromPricing[material.materialNumber]) === null || _materialsFromPricing2 === void 0 ? void 0 : _materialsFromPricing2.marketplaceOfferId
      });
    });
  };

  return {
    getMaterialsFromPricing: getMaterialsFromPricing,
    getMaterialsWithMarketPlaceData: getMaterialsWithMarketPlaceData
  };
};